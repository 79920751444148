import React from 'react'
import './collections.css';
import clownMonarch from '../../assets/clownMonarch.gif';

const Collections = () => {
  return (
    <div className="knotverse__collections" id="collections">
      <div className="knotverse__collections-clowntease">
        <img src={clownMonarch} alt="clown" />
      </div>  
      <div className="knotverse__collections-clowntext">
        <h1>"the clown's monarch"</h1>
        <h2 className="gradient__text">THE FIRST DIGITAL COLLECTIBLE IN A SERIES CALLED “MY LEGACY”</h2>
        <p>Auction ran from April 29th to may 6th.</p>
        <div className="knotverse__collections-opensea">
          <a href="https://opensea.io/collection/clown-my-legacy"><button type= "button">view on opensea</button></a>
        </div>
      </div>
    </div> 
  )
}
 
export default Collections