import React from 'react'
import './footer.css';
import ftologo from '../../assets/ftologo.svg';

const Footer = () => (
  <div className="knotverse__footer section__padding">
    <div className="knotverse__footer-links">
      <div className="knotverse__footer-links_logo">
        <img src={ftologo} alt="five to one" />
      </div>
      <div className="knotverse__footer-links_div">
        <h4>Official Links</h4>
        <a href="https://opensea.io/knotfest"><p>Knotfest on OpenSea</p></a>
        <a href="https://opensea.io/Slipknot"><p>Slipknot on OpenSea</p></a>
        <a href="https://opensea.io/clown"><p>Clown on OpenSea</p></a>
      </div>
      <div className="knotverse__footer-links_div">
        <h4>Brand Sites</h4>
        <a href="https://knotfest.com/"><p>KNOTFEST.COM</p></a>
        <a href="https://slipknot1.com/"><p>SLIPKNOT1.COM</p></a>
        <a href="https://www.rockawaymerch.com/"><p>ROCKAWAYMERCH.COM</p></a>
      </div>
      <div className="knotverse__footer-links_div">
        <h4>Get in Touch</h4>
        <p>help@knotverse.io</p>
        <h4>Company</h4>
        <a href="https://fivetoone.io/"><p>FIVETOONE.IO</p></a>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>info@fivetoone.io</p>
      </div>
    </div>

    <div className="knotverse__footer-copyright">
      <a href="https://fivetoone.io/"><p>2022 - FIVE TO ONE</p></a>
    </div>
  </div>
);

export default Footer;