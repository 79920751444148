import React from 'react';

import { Footer, Faq, Rewards, Roadmap, Anniversary, Collections, Metaverse, About, Header } from './containers';
import { Navbar } from './components';
import './App.css';

const App = () => {
  return (
    <div className="App">
        <div className="moving__bg">
          <Navbar />
          <Header />
        </div>
        <About />
        <div className="meta__bg">
          <Metaverse />
        </div>
        <div className="gradient__bg">
          <Collections />
          <Anniversary />
        </div>
        <div className="stock__bg">
          <Roadmap />
        </div>
        <div className="gradient__bg">
          <Rewards />
        </div>
        <Faq />
        <Footer />
    </div>
  )
}

export default App