import React from 'react'
import './metaverse.css';
import sksb from '../../assets/SKSB.png';

const Metaverse = () => {
  return (
  <div className="knotverse__metaverse" id="metaverse">
    <div className="knotverse__metaverse-sec">
        <img src={sksb} alt="sksb" />
    </div>
    <div className="knotverse__metaverse-read">
      <a href="https://knotfest.com/culture/slipknot-partner-with-the-sandbox-to-plant-their-flag-in-the-metaverse/"><button type= "button">read now</button></a>
    </div>
  </div> 
  ) 
}

export default Metaverse