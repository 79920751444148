import React from 'react'
import './anniversary.css';
import posters from '../../assets/posters.png';

const Anniversary = () => {
  return (
    <div>
      <div className="knotverse__anniversary">
        <h1>10 years of knotfest</h1>
        <h2 className="gradient__text">the knotfest collection - coming soon</h2>
      </div>
      <div className="knotverse__anniversary-posters">
          <img src={posters} alt="posters" />
      </div>
    </div>
  ) 
}
 
export default Anniversary