import React from 'react'
import './rewards.css';

const Rewards = () => {
  return (
    <div>
    <div className="knotverse__rewards" id="rewards">
      <div className="knotverse__rewards-text">
        <h2 className="gradient__text">holder rewards</h2>
        <p>airdrops + giveaways</p>
        <p>irl vip experiences</p> 
        <p>tickets + festival access</p>
        <p>stage memorabilia</p>
        <p>metaverse access + assets</p>
        <p>exclusive merch access</p>
        <p>and much more...</p>
      </div>
    </div> 
    </div> 
  )
}

export default Rewards