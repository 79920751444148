import React from 'react'
import './faq.css';

const Faq = () => {
  return (
    <div className="knotverse__faq-wrapper">
      <div className="knotverse__faq" id="faq">
        <h1>nft + web3 faq</h1>
      </div>
      <div className="knotverse__faq-text">
        <h2>What is an NFT?</h2>
        <p>Non-Fungible Tokens (NFTs) are unique digital items with blockchain-managed ownership. If something is non-fungible, it means that it cannot be replaced and it represents something unique in value. The token part of Non-Fungible Token refers to a digital certificate stored on a publicly verifiable distributed database, also known as a blockchain.</p>
        <h2>What is Web3?</h2>
        <p>Web2 refers to the version of the internet most of us know today. An internet dominated by companies that provide services in exchange for your personal data. Web3, in the context of Ethereum, refers to decentralized apps that run on the blockchain. These are apps that allow anyone to participate without monetising their personal data.</p>
        <h2>What are gas fees?</h2>
        <p>Gas refers to the unit that measures the amount of computational effort required to execute specific operations on the Ethereum network. Since each Ethereum transaction requires computational resources to execute, each transaction requires a fee. Gas refers to the fee required to conduct a transaction on Ethereum successfully.</p>
        <h2>How do I buy Ethereum?</h2>
        <p>To purchase Ethereum, enter its ticker symbol—ETH—in your exchange’s “buy” field and input the amount you want to buy. If you don’t want to buy a whole Ethereum token or don’t have enough money in your account for a full coin, you can purchase a fraction of one. For example, if the price of Ethereum is $2,000 and you invest $100, you will purchase 5% of an Ether coin.</p>
        <h2>What is a crytpo wallet?</h2>
        <p>“Wallet” is a pretty poor metaphor for a crypto/NFT wallet. The word “wallet” makes you think that securing a crypto wallet is the same as securing the wallet you keep in your pocket. Most importantly, it seems that if you have your crypto wallet then no one else can have it at the same time. Unfortunately, this is not the case.</p>
        <p>A wallet is a private key. Private keys are like a personal signature. In the real world, it’s common to use your personal signature to authorize documents or contracts. In the web3 space, only you can produce this signature (the private key), but the entire world knows how to check it by looking at your wallet address.</p>
        <p>In web3, the public key is the wallet address. The wallet address on Ethereum is the string of random letters and numbers that starts with “0x”.  Everyone can know your wallet address, and knowing your wallet address does not give any control over your wallet.</p>
        <p>In summary, the private key grants full control over the wallet. The seed phrase, usually 12-words long, is a shortcut to the private key and to the wallet itself. This is why it is so important to protect your seed phrase.</p>
        <h2>Have more questions?</h2>
        <a href="https://support.opensea.io/hc/en-us"><p>OpenSea Help Center</p></a>
        <a href="https://metamask.zendesk.com/hc/en-us"><p>Metamask Support</p></a>
      </div>
    </div> 
  ) 
}

export default Faq