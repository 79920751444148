import React from 'react'
import './about.css';
import discord from '../../assets/discord.svg';
import twitter from '../../assets/twitter.svg';
import instagram from '../../assets/instagram.svg';


const About = () => {
  return (
    <div className="knotverse__about" id="about">
      <div className="knotverse__about-heading">
        <h1 className="knotverse__about-what">what is knotverse?</h1>
        <h2 className="gradient__text">KNOTVERSE will be a fully immersive space to host Web3 experiences curated by Knotfest and Slipknot.</h2>
        <p>THE KNOTVERSE ROADMAP WILL HOLD ONE OF A KIND NFTS + GENERATIVE COLLECTIONS, PLUS METAVERSE CONCERTS & GAMING EXPERIENCES, RARE COLLABORATIONS, WEARABLES AND MUCH MORE. KNOTVERSE WILL ULTIMATELY OFFER FANS OF HEAVY CULTURE UNIQUE UTILITY THROUGH ACCESS TO MUSIC, IRL MEETUPS, EVENTS & FESTIVAL EXPERIENCES, ONE-OF-A-KIND MERCHANDISE AND ARTIST memorabilia.</p>
      </div>
      <div className="knotverse__about-icons">
        <a href="https://discord.gg/knotverse"><img src={discord} alt="discord" /></a>
        <a href="https://twitter.com/knotverseio"><img src={twitter} alt="twitter" /></a>
        <a href="https://www.instagram.com/knotverse.io/"><img src={instagram} alt="instagram" /></a>
      </div>
    </div> 
  )
}
export default About