import React from 'react'
import './header.css';
import logo from '../../assets/logo.svg';

const Header = () => {
  return (
    <div className="knotverse__header section_padding" id="home">
      <div className="knotverse__header-content text-blur-out">
        <h1>
          <img src={logo} alt="logo" />
        </h1>
      </div>
    </div>
  )
}

export default Header