import React from 'react'
import './roadmap.css';

const Roadmap = () => {
  return (
    <div className="knotverse__roadmap" id="roadmap">
      <div className="knotverse__roadmap-heading">
        <h1>knotverse roadmap</h1>
      </div> 
      <div className="knotverse__roadmap-qone">
        <div class="timeline">

          <div class="container left">
            <i class="icon fa fa-home"></i>
            <div class="content">
              <h2>community</h2> 
              <p>KNOTVERSE IS OFFICIALLY ANNOUNCED ALONGSIDE A BRAND NEW WEBSITE, DISCORD SERVER, AND THE BEGINNING OF A NEW WEB3 COMMUNITY, BRIDGING FANS OF HEAVY CULTURE WITH NEW AND INNOVATIVE EXPERIENCES</p>
            </div>
          </div>

          <div class="container right">
            <i class="icon fa fa-gift"></i>
            <div class="content">
              <h2>exclusive merch + events</h2>
              <p>KNOTFEST HAS PROVIDED FANS WITH INTERNATIONAL EVENTS FOR THE PAST DECADE, BUT NOW HAS THE OPPORTUNITY TO OFFER FANS MORE PERSONAL EXPERIENCES INCLUDING MEETUPS IN MAJOR CITIES, FESTIVAL AFTER PARTIES, RARE MERCHANDISE AND ARTIST memorabilia, SPECIAL KNOTFEST VIP ACCESS, AND MORE.</p>
            </div>
          </div> 

          <div class="container left">
            <i class="icon fa fa-user"></i>
            <div class="content">
              <h2>the collections</h2>
              <p>• "my legacy" clown collection</p>
              <p>• knotfest festival collection</p>
              <p>• knotfest generative collection</p>
              <p>• slipknot collection</p>
            </div>
          </div>
 
          <div class="container right">
            <i class="icon fa fa-running"></i>
            <div class="content">
              <h2>metaverse</h2>
              <p>metaverse experience launches in the sandbox</p>
            </div>
          </div>

          <div class="container left">
            <i class="icon fa fa-cog"></i>
            <div class="content">
              <h2>partners + collabs</h2>
              <p>... expect the unexpected ...</p>
            </div>
          </div>

          <div class="container right">
            <i class="icon fa fa-certificate"></i>
            <div class="content">
              <h2>voting rights</h2>
              <p>the community will vote on the direction and events of knotverse</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Roadmap