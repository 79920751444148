import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';

const Menu = () => (
  <>
  <p><a href="#home">home</a></p>
  <p><a href="#about">about</a></p>
  <p><a href="#metaverse">metaverse</a></p>
  <p><a href="#collections">collections</a></p>
  <p><a href="#roadmap">roadmap</a></p>
  <p><a href="#rewards">rewards</a></p>
  <p><a href="#faq">faqs</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="knotverse__navbar">
        <div className="knotverse__navbar-links">
          <div className="knotverse__navbar-links_container">
            <Menu />
          </div>
        </div>
        <div className="knotverse__navbar-menu">
          {toggleMenu
            ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)}/>
            : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)}/>
          }
          {toggleMenu && (
            <div className="knotverse__navbar-menu_container scale-up-center">
              <div className="knotverse__navbar-menu_container-links">
                <Menu />
              </div>
            </div> 
          )}
          
        </div>
    </div>
  )
}

export default Navbar